import { useIsHomePath } from '~/lib/utils';
import {
	Drawer,
	useDrawer,
	Text,
	Input,
	IconAccount,
	IconBag,
	IconSearch,
	Heading,
	IconMenu,
	IconCaret,
	Section,
	CountrySelector,
	Cart,
	CartLoading,
	Link,
	Popup
} from '~/components';
import { useParams, Form, Await, useMatches } from '@remix-run/react';
import { useWindowScroll } from 'react-use';
import { Disclosure } from '@headlessui/react';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useIsHydrated } from '~/hooks/useIsHydrated';
import { useCartFetchers } from '~/hooks/useCartFetchers';
import { Image } from '@shopify/hydrogen-react';

export function Layout({ children, layout }) {
	const [showCountryPopup, setShowCountryPopup] = useState(false);
	const [showCookiePopup, setShowCookiePopup] = useState(false);

	useEffect(() => {
		if (!localStorage.getItem('hideRegionPopup')) {
			setShowCountryPopup(true)
		} else {
			setShowCountryPopup(false)
		}
	}, []);

	useEffect(() => {
		const isCookieAccepted = localStorage.getItem('cookie')
		if (!showCountryPopup && !isCookieAccepted) {
			setShowCookiePopup(true)
		}
	}, [showCountryPopup]);

	useEffect(() => {
		if (showCookiePopup) {
			localStorage.setItem('cookie', true)
		}
	}, [showCookiePopup]);

	return (
		<>
			<script
				dangerouslySetInnerHTML={{
					__html: `
						(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
						m[i].l=1*new Date();
						for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
						k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
						(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

						ym(93223293, "init", {
									clickmap:true,
									trackLinks:true,
									accurateTrackBounce:true,
									webvisor:true
						});
						`,
					}}
				/>
			<noscript><div><img src="https://mc.yandex.ru/watch/93223293" style={{position: 'absolute', left: '-9999px'}} alt="" /></div></noscript>

			{!showCountryPopup && showCookiePopup &&
				(<Popup cancel={() => setShowCookiePopup(false)}>
					<div className="mt-3 mb-6 mx-7 md:mt-5 md:mb-12 md:mx-9">
						<Text>Продолжая использовать сайт http://antheiawoman.ru/ Вы даете свое согласие на обработку персональных данных, в соответствии с <Link to="/pages/политика-использования-cookie-файлов" className="border-b">политикой использования куки-файлов</Link>.
						</Text>
					</div>
				</Popup>)
			}
			{showCountryPopup && <CountrySelector cancelPopup={() => setShowCountryPopup(false)} />}
			<div className="bg-gradient fixed left-0 w-full h-full -z-10"></div>
			<div className="flex flex-col min-h-screen">
				<div className="">
					<a href="#mainContent" className="sr-only">
						Skip to content
					</a>
				</div>
				<Header
					title={layout?.shop.name ?? 'Antheia'}
					logo={layout?.shop?.brand?.logo?.image}
					menu={layout?.headerMenu}
					openRegion={() => setShowCountryPopup(true)}
				/>
				<main role="main" id="mainContent" className="flex-grow z-10">
					{children}
				</main>
			</div>
			<Footer menu={layout?.footerMenu} logo={layout?.shop?.brand?.logo?.image} title={layout?.shop.name ?? 'Antheia'} />
		</>
	);
}

function Header({ title, logo, menu, openRegion }) {
	const isHome = useIsHomePath();

	const {
		isOpen: isCartOpen,
		openDrawer: openCart,
		closeDrawer: closeCart,
	} = useDrawer();

	const {
		isOpen: isMenuOpen,
		openDrawer: openMenu,
		closeDrawer: closeMenu,
	} = useDrawer();

	const addToCartFetchers = useCartFetchers('ADD_TO_CART');

	// toggle cart drawer when adding to cart
	useEffect(() => {
		if (isCartOpen || !addToCartFetchers.length) return;
		openCart();
	}, [addToCartFetchers, isCartOpen, openCart]);

	return (
		<>
			<CartDrawer isOpen={isCartOpen} onClose={closeCart} />
			{menu && (
				<MenuDrawer isOpen={isMenuOpen} onClose={closeMenu} menu={menu} openRegion={openRegion} />
			)}
			<DesktopHeader
				isHome={isHome}
				title={title}
				logo={logo}
				menu={menu}
				openCart={openCart}
				openRegion={openRegion}
			/>
			<MobileHeader
				isHome={isHome}
				title={title}
				logo={logo}
				openCart={openCart}
				openMenu={openMenu}
			/>
		</>
	);
}

function CartDrawer({ isOpen, onClose }) {
	const [root] = useMatches();

	return (
		<Drawer open={isOpen} onClose={onClose} heading="Корзина" openFrom="right">
			<div className="grid">
				<Suspense fallback={<CartLoading />}>
					<Await resolve={root.data?.cart}>
						{(cart) => <Cart layout="drawer" onClose={onClose} cart={cart} />}
					</Await>
				</Suspense>
			</div>
		</Drawer>
	);
}

export function MenuDrawer({ isOpen, onClose, menu, openRegion }) {
	return (
		<Drawer open={isOpen} onClose={onClose} openFrom="left" heading="Menu">
			<div className="grid">
				<MenuMobileNav menu={menu} onClose={onClose} openRegion={openRegion} />
			</div>
		</Drawer>
	);
}

function MenuMobileNav({ menu, onClose, openRegion }) {
	return (
		<nav className="grid gap-4 p-6 sm:gap-6 sm:px-12 sm:py-8">
			{/* Top level menu items */}
			{(menu?.items || []).map((item) => (
				<span key={item.id} className="block">
					<Link
						to={item.to}
						target={item.target}
						onClick={onClose}
						className={({ isActive }) =>
							isActive ? 'pb-1 border-b -mb-px' : 'pb-1'
						}
					>
						<Text as="span" size="copy">
							{item.title}
						</Text>
					</Link>
				</span>
			))}
			<span
				className="block cursor-pointer"
				onClick={() => {
					openRegion()
					onClose()
				}}
			>
				<Text as="span" size="copy">
					Регион
				</Text>
			</span>
		</nav>
	);
}

function MobileHeader({ title, logo, isHome, openCart, openMenu }) {
	// useHeaderStyleFix(containerStyle, setContainerStyle, isHome);

	const params = useParams();

	return (
		<header
			role="banner"
			className={`text-primary flex lg:hidden items-center h-nav sticky z-40 top-0 justify-between w-full leading-none gap-4 px-4 md:px-8`}
		>
			<div className="flex items-center justify-start w-full gap-4">
				<button
					onClick={openMenu}
					className="relative flex items-center justify-center w-8 h-8"
				>
					<IconMenu />
				</button>
				{/* <Form
          method="get"
          action={params.lang ? `/${params.lang}/search` : '/search'}
          className="items-center gap-2 sm:flex"
        >
          <button
            type="submit"
            className="relative flex items-center justify-center w-8 h-8"
          >
            <IconSearch />
          </button>
          <Input
            className={
              isHome
                ? 'focus:border-contrast/20 dark:focus:border-primary/20'
                : 'focus:border-primary/20'
            }
            type="search"
            variant="minisearch"
            placeholder="Search"
            name="q"
          />
        </Form> */}
			</div>

			<Link
				className="flex items-center self-stretch leading-[3rem] md:leading-[4rem] justify-center flex-grow w-full h-full"
				to="/"
			>
				{logo ?
					<Image
						data={logo}
						className="object-contain object-center w-auto h-6 md:w-28 md:h-28"
					></Image>
					: <Heading className="font-bold text-center" as={isHome ? 'h1' : 'h2'}>{title}</Heading>
				}
			</Link>

			<div className="flex items-center justify-end w-full gap-4">
				{/* <Link
          to="/account"
          className="relative flex items-center justify-center w-8 h-8"
        >
          <IconAccount />
        </Link> */}
				<CartCount isHome={isHome} openCart={openCart} />
			</div>
		</header>
	);
}

function DesktopHeader({ isHome, menu, openCart, openRegion, title, logo }) {
	const params = useParams();
	const { y } = useWindowScroll();
	return (
		<header
			role="banner"
			className={`text-primary hidden h-nav lg:flex items-center sticky transition duration-300 z-40 top-0 justify-between w-full leading-none gap-8 px-12 py-8`}
		>
			<div className="flex gap-12">
				<nav className="flex gap-8 z-20">
					{/* Top level menu items */}
					{(menu?.items || []).map((item) => (
						<Link
							key={item.id}
							to={item.to}
							target={item.target}
							prefetch="intent"
							className={({ isActive }) =>
								isActive ? 'pb-1 border-b -mb-px' : 'pb-1'
							}
						>
							{item.title}
						</Link>
					))}
				</nav>
			</div>
			<Link className="font-bold absolute left-0 w-full flex justify-center" to="/" prefetch="intent">
				{logo ?
					<Image
						data={logo}
						className="object-contain object-center md:w-auto md:h-10"
						// style={{ filter: isHome ? "brightness(0) saturate(100%) invert(97%) sepia(0%) saturate(1838%) hue-rotate(330deg) brightness(102%) contrast(104%)" : "none" }}
					></Image>
					: <Heading className="font-bold text-center" as={isHome ? 'h1' : 'h2'}>{title}</Heading>
				}
			</Link>
			<div className="flex items-center gap-12">
				{/* <Form
          method="get"
          action={params.lang ? `/${params.lang}/search` : '/search'}
          className="flex items-center gap-2"
        >
          <Input
            className={
              isHome
                ? 'focus:border-contrast/20 dark:focus:border-primary/20'
                : 'focus:border-primary/20'
            }
            type="search"
            variant="minisearch"
            placeholder="Search"
            name="q"
          />
          <button
            type="submit"
            className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
          >
            <IconSearch />
          </button>
        </Form> */}
				{/* <Link
          to="/account"
          className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5"
        >
          <IconAccount />
        </Link> */}
				<span
					className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5 cursor-pointer"
					onClick={openRegion}
				>Регион</span>
				<CartCount isHome={isHome} openCart={openCart} />
			</div>
		</header>
	);
}

function CartCount({ isHome, openCart }) {
	const [root] = useMatches();

	return (
		<Suspense fallback={<Badge count={0} dark={isHome} openCart={openCart} />}>
			<Await resolve={root.data?.cart}>
				{(cart) => (
					<Badge
						dark={isHome}
						openCart={openCart}
						count={cart?.totalQuantity || 0}
					/>
				)}
			</Await>
		</Suspense>
	);
}

function Badge({ openCart, dark, count }) {
	const isHydrated = useIsHydrated();

	const BadgeCounter = useMemo(
		() => (
			<>
				<IconBag />
				{count > 0 ?
					<div
						className={`text-contrast bg-primary absolute bottom-1 right-1 text-[0.625rem] font-medium subpixel-antialiased h-3 min-w-[0.75rem] flex items-center justify-center leading-none text-center rounded-full w-auto px-[0.125rem] pb-px`}
					>
						<span>{count || 0}</span>
					</div>
					: ''
				}
			</>
		),
		[count, dark],
	);

	return isHydrated ? (
		<button
			onClick={openCart}
			className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5 z-20"
		>
			{BadgeCounter}
		</button>
	) : (
		<Link
			to="/cart"
			className="relative flex items-center justify-center w-8 h-8 focus:ring-primary/5 z-20"
		>
			{BadgeCounter}
		</Link>
	);
}

function Footer({ menu, logo, title }) {
	const isHome = useIsHomePath();
	const itemsCount = menu
		? menu?.items?.length + 1 > 4
			? 4
			: menu?.items?.length + 1
		: [];

	return (
		<Section
			divider={isHome ? 'none' : 'top'}
			as="footer"
			role="contentinfo"
			className="pt-12"
		>
			{/* <div>{logo}</div> */}
			<Link className="font-bold mx-auto mb-12 flex w-full justify-center md:justify-start" to="/" prefetch="intent">
				{logo ?
					<Image
						data={logo}
						className="object-contain object-center w-auto h-6 md:h-12"
					></Image>
					: <Heading className="font-bold text-center" as="h2">{title}</Heading>
				}
			</Link>
			<div className={`grid items-start grid-flow-row w-full gap-5 py-0 px-6 md:px-0 lg:px-0 md:gap-4 lg:gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-${itemsCount}
        text-primary overflow-hidden`}>
				<FooterMenu menu={menu} />
			</div>
			{/* <div
        className={`self-end pt-8 opacity-50 md:col-span-2 lg:col-span-${itemsCount}`}
      >
        &copy; {new Date().getFullYear()} / Antheia
      </div> */}
		</Section>
	);
}

const FooterLink = ({ item }) => {
	if (item.to.startsWith('http')) {
		return (
			<a href={item.to} target={item.target} rel="noopener noreferrer">
				{item.title}
			</a>
		);
	}

	return (
		<Link to={item.to} target={item.target} prefetch="intent">
			{item.title}
		</Link>
	);
};

function FooterMenu({ menu }) {
	const styles = {
		section: 'grid gap-4 justify-center md:justify-start',
		nav: 'grid gap-2 pb-6 justify-center md:justify-start',
	};

	return (
		<>
			{(menu?.items || []).map((item) => (
				<FooterLink key={item.id} item={item} />
				// <section key={item.id} className={styles.section}>
				// 	<Disclosure>
				// 		{({ open }) => (
				// 			<>
				// 				<Disclosure.Button className="text-left md:cursor-default">
				// 					<Heading className="flex justify-center" size="base" as="h3">
				// 						{item.title}
				// 						{item?.items?.length > 0 && (
				// 							<span className="md:hidden">
				// 								<IconCaret direction={open ? 'up' : 'down'} />
				// 							</span>
				// 						)}
				// 					</Heading>
				// 				</Disclosure.Button>
				// 				{item?.items?.length > 0 ? (
				// 					<div
				// 						className={`${open ? `max-h-48 h-fit` : `max-h-0 md:max-h-fit`
				// 							} overflow-hidden transition-all duration-300`}
				// 					>
				// 						<Suspense data-comment="This suspense fixes a hydration bug in Disclosure.Panel with static prop">
				// 							<Disclosure.Panel static>
				// 								<nav className={styles.nav}>
				// 									{item.items.map((subItem) => (
				// 										<FooterLink key={subItem.id} item={subItem} />
				// 									))}
				// 								</nav>
				// 							</Disclosure.Panel>
				// 						</Suspense>
				// 					</div>
				// 				) : null}
				// 			</>
				// 		)}
				// 	</Disclosure>
				// </section>
			))}
		</>
	);
}
