import {IconClose, Link} from '~/components';

export function Popup({children, cancel}) {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      id="modal-bg"
    >
      <div className="fixed inset-0 transition-opacity bg-opacity-75 bg-primary/40"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
          <div
            className="absolute right-0 bottom-0 md:bottom-auto md:top-[100px] overflow-hidden text-center transition-all transform shadow-xl bg-contrast w-full md:w-[375px] pt-9 md:mx-12"
            role="button"
            onClick={(e) => {
              e.stopPropagation();
            }}
            onKeyPress={(e) => {
              e.stopPropagation();
            }}
            tabIndex={0}
          >
            <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
              <button
                onClick={cancel}
                className="p-4 -m-4 transition text-primary hover:text-primary/50"
              >
                <IconClose aria-label="Close panel" />
              </button>
            </div>
            {children}
          </div>
      </div>
    </div>
  );
}
